// Colors
$header-color: #3a3286;
$text-color: #0d0939;
$text-secondary-color: #004448;
$disable: #1f2525;

$primary: #0d0939;
$secondary: #d8b56d;
$light: #f4f3f8;
// $success: #c2ba93;
// $danger: #e60f16;
// $info: #e8f1f2;
// $warning: #fdf3ee;
// $gray: #707070;
$dark: #3a3286;

$text-muted: tint-color(#0d0939, 50%);
$link-decoration: none;
$link-color: $dark;
$link-hover-color: $secondary;

// Font weight
$font-weight-lighter: 300;
$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;

$form-check-input-checked-color: $secondary;
$form-check-input-checked-bg-color: transparent;
$form-check-input-focus-box-shadow: #3a3286;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  3xl: 1850px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  3xl: 1610px,
);

$custom-spacers: (
  row: 0.75rem,
  1: 3.5rem,
  2: 4rem,
  3: 4.5rem,
  4: 5rem,
  5: 5.5rem,
  6: 6rem,
  7: 6.5rem,
  8: 7rem,
  9: 7.5rem,
  10: 8rem,
  11: 8.5rem,
);

$font-size-base: 1.0625rem;

$font-sizes: (
  1: 3.5rem,
  2: 3rem,
  3: 2.25rem,
  4: 1.6rem,
  5: 1.25rem,
  6: 1rem,
  "small": 0.9375rem,
);

// Custom classses
$utilities: (
  "c-font-size": (
    property: font-size,
    class: "c-fs",
    responsive: true,
    rfs: true,
    values:
      map-merge(
        $font-sizes,
        (
          "small": 14px,
          "24": 1.5rem,
        )
      ),
  ),
  // Margins utilities
  "c-margin":
    (
      responsive: true,
      property: margin,
      class: c-m,
      values: $custom-spacers,
    ),
  "c-margin-x": (
    responsive: true,
    property: margin-right margin-left,
    class: c-mx,
    values: $custom-spacers,
  ),
  "c-margin-y": (
    responsive: true,
    property: margin-top margin-bottom,
    class: c-my,
    values: $custom-spacers,
  ),
  "c-margin-top": (
    responsive: true,
    property: margin-top,
    class: c-mt,
    values: $custom-spacers,
  ),
  "c-margin-end": (
    responsive: true,
    property: margin-right,
    class: c-me,
    values: $custom-spacers,
  ),
  "c-margin-bottom": (
    responsive: true,
    property: margin-bottom,
    class: c-mb,
    values: $custom-spacers,
  ),
  "c-margin-start": (
    responsive: true,
    property: margin-left,
    class: c-ms,
    values: $custom-spacers,
  ),
  // Padding utilities
  "c-padding":
    (
      responsive: true,
      property: padding,
      class: c-p,
      values: $custom-spacers,
    ),
  "c-padding-x": (
    responsive: true,
    property: padding-right padding-left,
    class: c-px,
    values: $custom-spacers,
  ),
  "c-padding-y": (
    responsive: true,
    property: padding-top padding-bottom,
    class: c-py,
    values: $custom-spacers,
  ),
  "c-padding-top": (
    responsive: true,
    property: padding-top,
    class: c-pt,
    values: $custom-spacers,
  ),
  "c-padding-end": (
    responsive: true,
    property: padding-right,
    class: c-pe,
    values: $custom-spacers,
  ),
  "c-padding-bottom": (
    responsive: true,
    property: padding-bottom,
    class: c-pb,
    values: $custom-spacers,
  ),
  "c-padding-start": (
    responsive: true,
    property: padding-left,
    class: c-ps,
    values: $custom-spacers,
  ),
  "c-max-width": (
    responsive: true,
    property: max-width,
    class: c-mw,
    values: (
      460: 460px,
      565: 565px,
    ),
  ),
  "c-min-height": (
    responsive: true,
    property: min-height,
    class: c-min-h,
    values: (
      380: 380px,
      465: 465px,
      565: 565px,
    ),
  ),
);
